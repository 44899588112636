<template>
	<div class="u-page u-error-page">
		<MoleculesAdSlot ad_id="sky-left" ad_unit_small="" ad_unit_large="d_sky_left" />

		<div class="u-content-area">
			<HeaderWrapper />

			<slot />

			<BreadcrumbWrapper />

			<FooterWrapper></FooterWrapper>
		</div>

		<MoleculesAdSlot ad_id="sky-right" ad_unit_small="" ad_unit_large="d_sky_right" />
	</div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
	.u-error-page {
		.u-header {
			box-shadow: none !important;
		}
	}
</style>
